import React from "react"
import { Row, Col, Card, Divider, Tag, Typography, Badge } from "antd"
import ExperienceImage from './experience_Image';

import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Title, Text } = Typography;

const BookingCard = (props) => {

  const renderTickets = () => {
    let tickets = []
    if(props.booking.variants.length > 0){
      props.booking.variants.forEach((variant, index) => {
        if(variant.quantity > 0){
          let ticket = `${variant.quantity} x ${variant.title}`
          tickets.push(ticket)
        }
      })
    }
    if(!tickets)
      tickets = "No tickets"
    return tickets.join(", ");
  }

  const renderBookingImage = () => {
    if(props.experience){
      if(props.experience.images){
        if(props.experience.images.length > 0){
          return <div className="experience-image"><ExperienceImage experience_image={props.experience.images[0].src} /></div>
        }
        else{
          return <div className="experience-image"></div>
        }
      }
      else{
        return <div className="experience-image"></div>
      }
    }
    else{
      return <div className="experience-image"></div>
    }
    
  }

  const createdTimeCompare = () => {
    let difference = moment().diff(props.booking.created_at, 'minutes')
    if(difference <= 60){ return "inherit" }
    return "none"
  }
  

  return (
    <Badge.Ribbon style={{display: createdTimeCompare()}} text="New" color="#ffdb78">
      <Card className="booking-card" onClick={props.onClick}>  
        <Row style={{flexWrap: "nowrap"}}>
          <Col className="date" flex="45px">
            <Title level={5} type="secondary" className="base-font" style={{fontWeight: 400}}>{moment(props.booking.starts_at).format("ddd")}</Title>
            <Title level={3} type="default">{moment(props.booking.starts_at).format("DD")}</Title>
            <Title level={5} type="primary" className="base-font">{moment(props.booking.starts_at).format("MMM")}</Title>
          </Col>
          <Col flex="auto">
            <Row gutter={20} align="middle" style={{flexWrap: "nowrap"}}>
              <Col flex="95px">
                {renderBookingImage()}
              </Col>
              <Col flex="auto" style={{maxWidth: "calc(100% - 95px)"}}>
                <Row>
                  <Col span={24}>
                    <Title level={4}>{props.booking.guest_name}</Title>
                  </Col>
                  <Col span={24}>
                    <Title level={5} style={{fontWeight: 450}}>{props.booking.title}</Title>
                  </Col>
                  <Col span={24}>
                    <Divider type="horizontal" />
                  </Col> 
                  <Col span={24}>
                    <Row gutter={10} style={{flexWrap: "nowrap"}} className="font-small">
                      <Col>
                        <Text type="secondary">{ props.booking.type === "Shopify" ? `Ref: ${props.booking.order_name}` : "Manual"}</Text>
                      </Col>
                      <Col>
                        <Divider type="vertical" />
                      </Col>
                      <Col>
                        <Text type="secondary">${props.booking.total_price}</Text>
                      </Col>
                      <Col>
                        <Divider type="vertical" />
                      </Col>
                      <Col>
                        <Text type="secondary" style={{maxWidth: "250px"}} ellipsis={true}>{renderTickets()}</Text>
                      </Col>
                    </Row>
                  </Col>
                </Row> 
              </Col>
            </Row>
          </Col>
          <Col className="center" flex="170px">
            <Tag className="font-small" color="#FFE0DD" style={{color: "black"}}>{moment(props.booking.starts_at).format("hh:mm A")}</Tag>
          </Col>
        </Row>
      </Card>
    </Badge.Ribbon>
   )
}

export default BookingCard