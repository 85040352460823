import React, { useContext, useEffect, useState } from "react";
import { message, Button, Layout, Row, Col, DatePicker, Select, Input, Tooltip, Typography, Space } from "antd"
import { SyncOutlined } from '@ant-design/icons';
import ImpersonationBanner from "../components/impersonationBanner"
import ViewBookingDrawer from "../components/booking_ViewDrawer"
import CreateBookingDrawer from "../components/booking_CreateDrawer"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import BookingCard from "../components/booking_Card";
import LoadingPage from "../components/loadingPage"
import LoadingGIF from "../components/loadingGIF"
import { navigate } from "gatsby";
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Header, Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;




const Bookings = () => {

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [selectedBooking, setSelectedBooking] = useState({});
  const [selectedExperience, setSelectedExperience] = useState({});
  const [viewBookingDrawerVisible, setViewBookingDrawerVisible] = useState(false);
  const [createBookingDrawerVisible, setCreateBookingDrawerVisible] = useState(false);
  const [experienceFilter, setExperienceFilter] = useState("all")
  const [generatingCSV, setGeneratingCSV] = useState(false);
  const [searchMode, setSearchMode] = useState(false)

  useEffect(() => {
    if (state.auth_state)
      dispatch({ type: 'ROUTE', route: "/bookings" })
  }, [state.auth_state, dispatch])


  const handleRouting = () => {
    if (state.role_level > 0 && !state.profile)
      navigate('/users')
  }
  useEffect(handleRouting);

  const refreshList = () => {
    if (searchMode) {
      dispatch({ type: 'REFRESH', toRefresh: 'refreshSearchBookings' });
    }
    else {
      dispatch({ type: 'REFRESH', toRefresh: 'refreshBookings' });
    }

    // dispatch({ type:'REFRESH', toRefresh: 'refreshFrames'});
  }

  // RENDER FUNCTIONS
  const changeDateFilter = (dates, date_strings) => {
    if (searchMode) {
      console.log(dates)
      dispatch({ type: "BOOKINGS_SEARCH_DATE", start: dates[0], finish: dates[1] });
    }
    else {
      dispatch({ type: "BOOKINGS_DATE", start: dates[0], finish: dates[1] });
    }
  }

  const handleSearch = async (value) => {
    console.log(value)
    let type = "name"
    if (value) {
      if (value.match(/\d/)) {
        type = "order"
        console.log("IS NUMBER")
      }
      if (value.includes("@")) {
        type = "email"
        console.log("IS EMAIL")
      }
    }
    console.log("TYPE:", type)
    setSearchMode(true)
    dispatch({ type: "BOOKINGS_SEARCH", search_type: type, query: value })
  }

  const renderBookingCards = () => {
    console.log("Booking cards")
    let bookings_to_render = []
    let cards = [];
    if (state.experiencesLoaded) {
      if (searchMode && state.searchBookingsLoaded) {
        bookings_to_render = state.searchBookingsData;
      }
      else {
        if (state.bookingsLoaded) {
          bookings_to_render = state.bookingsData;
        }
      }

      bookings_to_render.forEach((booking, index) => {
        let experience_data = {};
        for (let experience of state.experiencesData) {
          if (experience.title === booking.title) {
            experience_data = experience
          }
        }
        if (experienceFilter !== "all") {
          if (experienceFilter === booking.title) {
            cards.push(<Col key={index} span={24}><BookingCard experience={experience_data} booking={booking} onClick={() => showViewBookingDrawer(booking, experience_data)} /></Col>)
          }
        }
        else {
          cards.push(<Col key={index} span={24}><BookingCard experience={experience_data} booking={booking} onClick={() => showViewBookingDrawer(booking, experience_data)} /></Col>)
        }
      })
    }
    console.log("CARDS LENGTH", cards.length)
    if (searchMode) {
      if (cards.length === 0) {
        cards.push(<div key={"none"}>No bookings found</div>)
      }
      else if (cards.length === 20) {
        cards.push(<div key={"none"} style={{ marginTop: "20px" }}>Only 20 results shown. Refine search to display more bookings</div>)
      }
    }
    else {
      if (cards.length === 0) {
        cards.push(<div key={"none"}>No bookings for this experience</div>)
      }
    }

    return cards
  }

  const renderExperienceOptions = () => {
    if (state.experiencesLoaded && state.experiencesData) {
      let options = []
      options.push(<Option key={"all"} value="all">All Experiences</Option>)
      state.experiencesData.forEach((experience, index) => {
        options.push(<Option key={index} value={experience.title}>{experience.title}</Option>)
      })
      return options
    }
    else {
      return <Option value="all">Loading...</Option>
    }
  }

  const downloadCSV = async () => {
    let csv_data = await generateCSVData();
    console.log(csv_data);
    if (csv_data) {
      let csvContent = csv_data.map(e => e.join(",")).join("\n");

      let filename_prefix = experienceFilter !== "all" ? experienceFilter : "bookings"
      const fileName = filename_prefix + " " + moment().format("DD-MM");
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      message.info("No bookings to export")
    }

    setGeneratingCSV(false)
  }

  const generateCSVData = async () => {
    console.log("Compiling CSV download")
    let data = [
      ["experience", "reference", "date", "time", "ticket", "quantity", "source", "first_name", "last_name", "email", "phone", "purchase_date", "purchase_time"]
    ]
    let bookings_to_export = []
    if (state.experiencesLoaded) {
      if (searchMode && state.searchBookingsLoaded) {
        bookings_to_export = state.searchBookingsData;
      }
      else {
        if (state.bookingsLoaded) {
          bookings_to_export = state.bookingsData;
        }
      }

      bookings_to_export.forEach((booking, booking_index) => {
        let export_booking = experienceFilter !== "all" ? experienceFilter === booking.title ? true : false : true;
        if (export_booking) {
          booking.variants.forEach((variant_data, variant_index) => {
            let first_name, last_name;
            if (booking.type === "Shopify") {
              let first_name_split = booking.guest_name.indexOf(' ');
              if (first_name_split > 0) {
                first_name = booking.guest_name.slice(0, first_name_split);
                last_name = booking.guest_name.slice(first_name_split + 1, booking.guest_name.length);
              }
              else {
                first_name = booking.guest_name;
                last_name = "";
              }
            }
            else {
              first_name = booking.guest_first_name;
              last_name = booking.guest_last_name;
            }
            let booking_data = [
              booking.title,
              booking.order_name,
              moment(booking.starts_at).format("DD/MM/YYYY"),
              moment(booking.starts_at).format("hh:mm A"),
              variant_data.title,
              variant_data.quantity,
              booking.type === "Shopify" ? `Online` : "Manual",
              first_name,
              last_name,
              booking.guest_email,
              booking.guest_phone,
              moment(booking.created_at).format("DD/MM/YYYY"),
              moment(booking.created_at).format("hh:mm A")
            ]
            data.push(booking_data)
          })
        }
      })
    }
    console.log("CARDS LENGTH", data.length)
    if (data.length === 1) {
      message.info("No Bookings to export!")
    }
    else {
      message.info("No Bookings to export!")
    }
    return data;

  }

  const filterExperience = (value) => {
    setExperienceFilter(value)
  }

  const bookingCreated = (response) => {
    message.info(response);
    dispatch({ type: 'SELECT', toSelect: 'experienceSelected', value: undefined });
    closeCreateBookingDrawer();
    refreshList();
  }

  const handleBookingDeleted = () => {
    closeViewBookingDrawer();
    refreshList();
  }

  const showViewBookingDrawer = (booking, experience_data) => {
    setSelectedBooking(booking)
    setSelectedExperience(experience_data)
    setViewBookingDrawerVisible(true);
  };

  const closeViewBookingDrawer = () => {
    dispatch({ type: 'SELECT', toSelect: 'experienceSelected', value: undefined });
    dispatch({ type: 'SELECT', toSelect: 'daySelected', value: undefined });
    dispatch({ type: 'SELECT', toSelect: 'timeslotSelected', value: undefined });
    setViewBookingDrawerVisible(false);
  };

  const showCreateBookingDrawer = () => {
    dispatch({ type: 'SELECT', toSelect: 'experienceSelected', value: undefined });
    dispatch({ type: 'SELECT', toSelect: 'daySelected', value: undefined });
    dispatch({ type: 'SELECT', toSelect: 'timeslotSelected', value: undefined });
    setCreateBookingDrawerVisible(true);
  };

  const closeCreateBookingDrawer = () => {
    setCreateBookingDrawerVisible(false);
  }

  const handleExitImpersonation = () => {
    dispatch({ type: 'IMPERSONATE', profile: "", imp_username: "" });
    navigate('/users')
  }

  const handleExitSearch = () => {
    setSearchMode(false);
  }


  return (
    <div id="bookings-page" className="page">
      <ImpersonationBanner role_level={state.role_level} username={state.imp_username} profile={state.profile} onExit={handleExitImpersonation} />
      <Header id="header">
        <Row className="main-row">
          <Col className="title" span={6}>
            <Title>Bookings</Title>
          </Col>
          <Col flex="auto">
            <Row gutter={12} justify="end" align="middle" width="600px" height="100%">
              <Col className="search" flex={"250px"}>
                <Search
                  loading={!state.searchBookingsLoaded}
                  placeholder="Search bookings..."
                  onSearch={handleSearch}
                />
              </Col>
              <Col className="export-booking" flex={"70px"}>
                <Button block type="secondary" disabled={!state.bookingsLoaded} loading={generatingCSV ? true : false} onClick={() => {
                  setGeneratingCSV(true)
                  setTimeout(() => {
                    downloadCSV()
                  }, 2000)
                }}>
                  Export
                </Button>
              </Col>
              <Col className="create-booking" flex={"150px"}>
                <Button block type="primary" onClick={showCreateBookingDrawer}>Create Booking</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="lower-row" justify="start" gutter={12}>
          <Col className="experience-selector" flex={"250px"}>
            <Select defaultActiveFirstOption defaultValue="all" loading={!state.experiencesLoaded} onChange={filterExperience} style={{ width: "100%" }}>
              {renderExperienceOptions()}
            </Select>
          </Col>
          <Col flex={"250px"}>
            <RangePicker
              inputReadOnly
              picker={searchMode ? "month" : "date"}
              value={searchMode ? [state.searchBookingsDateStart, state.searchBookingsDateFinish] : [state.bookingsDateStart, state.bookingsDateFinish]}
              onChange={changeDateFilter}
              format={['DD/MM/YYYY', 'DD/MM/YY']}
              style={{ width: "100%" }} />
          </Col>
          <Col flex={"40px"}>
            <Tooltip placement="right" title={"Refresh bookings"}>
              <Button
                icon={<SyncOutlined className="refreshIcon" spin={searchMode ? !state.searchBookingsLoaded : !state.bookingsLoaded} />}
                onClick={refreshList}
              />
            </Tooltip>
          </Col>
          {searchMode && <Col className="export-booking" flex={"70px"}>
            <Button block type="secondary" disabled={!state.bookingsLoaded} onClick={handleExitSearch}>
              Exit search
            </Button>
          </Col>}
          <Col flex={"30px"}>
            <Space className={!state.bookingsLoaded ? "" : "hidden"} align='center' style={{ height: "100%" }}>
              <LoadingGIF width="30px" />
            </Space>
          </Col>
        </Row>
      </Header>
      <Content>
        <LoadingPage className={searchMode === true ? state.searchBookingsLoaded ? "loaded" : "loading" : state.bookingsLoaded && state.experiencesLoaded ? "loaded" : "loading"} />
        <Row className="content-container" gutter={[0, 20]}>
          {renderBookingCards()}
        </Row>
      </Content>
      <ViewBookingDrawer experience={selectedExperience} booking={selectedBooking} visible={viewBookingDrawerVisible} onDelete={handleBookingDeleted} onClose={closeViewBookingDrawer} />
      <CreateBookingDrawer visible={createBookingDrawerVisible} onSuccess={bookingCreated} onClose={closeCreateBookingDrawer} />
    </div>
  )
}

export default Bookings;
