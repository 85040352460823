import React, {useEffect, useState, useContext} from "react"
import { message, Row, Col,  Drawer, Divider, Descriptions, Button, Popover, Tag, Typography, Space } from "antd"
import { APIDeleteBooking } from "../scripts/bookings"
import ExperienceImage from './experience_Image';
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import moment from "moment-timezone";
moment.tz.setDefault('Australia/Sydney');

const { Title } = Typography;



const ViewBookingDrawer = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)

  const renderTickets = (variants) => {
    let tickets = [];
    if(variants.length > 0){
      variants.forEach((variant, index) => {
        if(variant.quantity > 0){
          tickets.push(<p key={index}>{variant.quantity} x {variant.title}</p>)
        }
      })
    }
    if(tickets.length === 0)
      tickets.push(<p>No tickets</p>);
    return tickets;
  }
  
  
  const deleteBooking = async () => {
    setDeleteLoading(true)
    await APIDeleteBooking(props.booking.id)
      .then(response => {
        setDeleteLoading(false)
        console.log(response)
        if(response.data.statusCode === 200){
          console.log(response)
          dispatch({ type:'REFRESH', toRefresh: 'refreshBookings'});
          message.info("Booking deleted!")
          props.onDelete("Booking deleted");  
        }
      })
      .catch(error => {
        console.error(error)
        setDeleteLoading(false)
        if(error.type === "validation"){
          message.error("Error: No User deleted")
        }
        else{
          message.error("An error occured!")
        }
      })
  
  }

  const renderBookingImage = () => {
    if(props.experience){
      if(props.experience.images){
        if(props.experience.images.length > 0){
          return <div className="experience-image"><ExperienceImage experience_image={props.experience.images[0].src} /></div>
        }
        else{
          return <div className="experience-image"></div>
        }
      }
    }
    else{
      return <div className="experience-image"></div>
    }
    
  }

  useEffect(() => {
    if(props.visible === false){
      setPopoverVisible(false)
    }
  }, [props.visible]);

  return (
    <Drawer
      width={800}
      onClose={props.onClose}
      visible={props.visible}
      bodyStyle={{ padding: 80 }}
      className="view-booking-drawer"
    >
      <Row className="header" style={{flexWrap: "nowrap"}}>
        <Col flex="auto">
          <Row gutter={20} align="middle" style={{flexWrap: "nowrap"}}>
            <Col flex="95px">
              {renderBookingImage()}
            </Col>
            <Col flex="auto" style={{maxWidth: "calc(100% - 95px)"}}>
              <Row>
                <Col span={24}>
                  <Title level={2}>{props.booking.title}</Title>
                </Col>
                <Col span={24}>
                  <Title className="text-light" level={5} type="secondary">{ props.booking.type === "Shopify" ? `Booking reference: ${props.booking.order_name}` : "Manual booking"}</Title>
                </Col>
              </Row> 
            </Col>
          </Row>
        </Col>
        <Col className="center" flex="110px">
          <Tag color="#FFE0DD" style={{color: "black"}}>{moment(props.booking.starts_at).format("hh:mm A")}</Tag>
        </Col>
        <Col className="date" flex="40px" style={{paddingRight: 0}}>
          <Title level={5} type="secondary" className="base-font" style={{fontWeight: 400}}>{moment(props.booking.starts_at).format("ddd")}</Title>
          <Title level={3} type="default">{moment(props.booking.starts_at).format("DD")}</Title>
          <Title level={5} type="primary" className="base-font">{moment(props.booking.starts_at).format("MMM")}</Title>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Title type="secondary" className="text-light" style={{marginBottom: "10px"}} level={4}>Booking Info</Title>
          
          <Descriptions size="small" column={1}>
            <Descriptions.Item label="Source">{props.booking.type === "offline_booking" ? "Manual" : "Online"}</Descriptions.Item>
            <Descriptions.Item label="Reference">{ props.booking.type === "Shopify" ? `${props.booking.order_name}` : "-"}</Descriptions.Item>
            <Descriptions.Item label="Price">${ props.booking.total_price}</Descriptions.Item>
            <Descriptions.Item label="Experience">{props.booking.title}</Descriptions.Item>
            <Descriptions.Item className="tickets" label="Tickets">{Object.keys(props.booking).length > 0 ? renderTickets(props.booking.variants) : "-"}</Descriptions.Item>
            <Descriptions.Item label="Quantity">{props.booking.total_quantity}</Descriptions.Item>
            <Descriptions.Item label="Purchase Date">{moment(props.booking.created_at).format("MMM Do [at] hh:mm A")}</Descriptions.Item>
            <Descriptions.Item label="Time slot">{moment(props.booking.starts_at).format("MMM Do [at] hh:mm A")}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Title type="secondary" className="text-light" style={{marginBottom: "10px"}} level={4}>Customer Info</Title>
          <Descriptions size="small" column={1}>
            <Descriptions.Item label="Name">{props.booking.guest_name}</Descriptions.Item>
            <Descriptions.Item label="Email">{props.booking.guest_email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{props.booking.guest_phone || "-" }</Descriptions.Item>
            {/* <Descriptions.Item label="Address">{props.booking.guest_address || "-"}</Descriptions.Item> */}
          </Descriptions>
        </Col>
      </Row>
      {props.booking.type === "offline_booking" && 
        <Row gutter={10}>
          <Col span={8}>
          <Popover visible={popoverVisible} onVisibleChange={visible => setPopoverVisible(props.visible ? visible : false)} content={<Space size={10}>Are you sure?<Button type="primary" loading={deleteLoading} danger onClick={deleteBooking}>YES</Button></Space>} trigger={"click"}>
            <Button danger>
              Delete booking
            </Button>
          </Popover>
          </Col>
        </Row> }
    </Drawer>
   )
}
export default ViewBookingDrawer

